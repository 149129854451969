/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

tui-dropdown-host {
  tui-dropdown {
    z-index: 3;
  }

  tui-alert-host {
    .t-wrapper {
      z-index: 4;
    }
  }

  tui-dialog-host {
    z-index: 3;
  }
}

:root {
  --tui-heading-font: "Lexend", sans-serif;
  --tui-text-font: "Lexend", sans-serif;

  --tui-primary: #0e43ca;
  --color-code-bg: #252935;
  --color-line-gray: #e8e8eb;

  --color-text-main: #060c26;
  --color-text-second: #717682;
  --color-line-gray: #e8e8eb;
  --color-link-active: #2071cc;
  --color-link-hover: #f3f6f8;
  --color-bg-light: #f8f7fa;
  --color-page-active: #ff1767;
  --color-input-primary: #f3f6f8;
  --color-input-border: #477cff;
  --color-code-bg: #252935;
  --color-code-main: #e1ebfe;
  --color-code-keyword: #ff6675;
  --color-code-class: #bf9dff;
  --color-code-variable: #69c6ff;
  --color-code-string: #81bcff;
  --color-code-params: #ffa259;
  --color-code-tag: #74e59d;
  --color-code-number: #ff6262;
  --color-code-comment: #6c7f93;
  --color-button-primary: #3389ff;
  --color-button-primary-hover: #2e7ae6;
  --color-button-primary-active: #296dcc;
  --color-button-secondary: #717682;
  --color-button-secondary-hover: #5d6068;
  --color-button-secondary-active: #4b4f5b;
  --color-button-warning: #ef5c5c;
  --color-button-warning-hover: #d65151;
  --color-button-warning-active: #bd4848;
  --color-success: #00e08f;

  --tui-chart-1: #feac5e;
  --tui-chart-2: #ff5f6d;
  --tui-chart-3: #4bc0c8;
  --tui-chart-4: #9795cd;

  .flex-center-hori {
    display: flex;
    justify-content: center;
  }

  .flex-center-verti {
    display: flex;
    align-items: center;
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .column {
    flex-direction: column;
  }

  .row {
    flex-direction: row;
  }

  .shadow {
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
  }

  .shadow-hover {
    box-shadow: 0px 12px 36px 0px rgba(0, 0, 0, 0.2);
  }

  .shadow-dropdown {
    box-shadow:
      0px 3px 6px 0px rgba(0, 0, 0, 0.05),
      0px 11px 11px 0px rgba(0, 0, 0, 0.04),
      0px 25px 15px 0px rgba(0, 0, 0, 0.03);
  }

  .shadow-input {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  }

  .shadow-notification {
    box-shadow: 0px 8px 16px 0px rgba(51, 51, 51, 0.2);
  }

  .shadow-modal {
    box-shadow: 0px 18px 30px 0px rgba(51, 51, 51, 0.64);
  }

  .shadow-tooltip {
    box-shadow: 0px 18px 30px 0px rgba(51, 51, 51, 0.64);
  }

  .border-d-left {
    box-shadow:
      -1px 0px 0px 0px #dddfe0 inset,
      0px 1px 0px 0px #dddfe0 inset,
      0px -1px 0px 0px #e7e8ea inset;
  }

  .border-d-right {
    box-shadow: -1px 0px 0px 0px #dddfe0 inset;
  }

  .border-d-top {
    box-shadow: 0px 1px 0px 0px #dddfe0 inset;
  }

  .border-d-bottom {
    box-shadow: 0px -1px 0px 0px #dddfe0 inset;
  }

  .border-d-left-right {
    box-shadow:
      -1px 0px 0px 0px #e7e8ea inset,
      1px 0px 0px 0px #dddfe0 inset;
  }

  .border-d-top-bottom {
    box-shadow:
      -1px 0px 0px 0px #dddfe0 inset,
      0px 1px 0px 0px #dddfe0 inset,
      0px -1px 0px 0px #dddfe0 inset;
  }
}

.right-sidebar {
  overflow-y: auto;
  overflow-x: auto;
  padding: 2rem;
  height: 100%;
  min-width: 900px;
  max-width: 1100px;
}

//drawer
.DrawerMenu {
  position: fixed;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  -webkit-transform: translateX(400%);
  -ms-transform: translateX(400%);
  transform: translateX(400%);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition:
    transform 0.5s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
}

.DrawerMenu.open {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.MenuContainer {
  contain: content;
  background: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  width: 929px;
  overflow-y: auto;
  overflow-x: auto;
  padding: 0.5rem 0;
  height: 100%;
  max-width: 1029px;
}

.MenuOverlay {
  width: calc(100% - 929px);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.38);
  z-index: -1;
}

.overlay.open {
  opacity: 1;
  z-index: 1;
}
